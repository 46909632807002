
import React from 'react';


export default () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 388.5 922.5 134.7">
    <g>
        <path fill="#EB4934" d="M56.6,521.9c-22,0-56.6,0-56.6,0V390.5c0,0,31.7,0,51.3,0c17.1,0,30.4,15,30.4,32.8
            c0,12.3-4.9,22.7-15.9,27.3v0.3c12.8,0.3,27.5,15.9,27.5,32.7C93.5,506.8,75.3,521.9,56.6,521.9z M42.1,411.2c-6.6,0-17.7,0-17.7,0
            v32.2c0,0,11.2,0,17.4,0c8.4,0,14.8-6.8,14.8-17.1C56.6,418.5,50.6,411.2,42.1,411.2z M48.8,464c-6.6,0-24.4,0-24.4,0v37.7
            c0,0,17.9,0,24.2,0c9.1,0,18.7-5.5,18.7-19C67.3,470,57.4,464,48.8,464z"/>
        <path fill="#EB4934" d="M210.5,521.9l-39-56.1v56.1H147V390.5c0,0,28.8,0,45.2,0c21.6,0,39.4,15.4,39.4,39.4
            c0,21.1-15.4,33-32,36.3c2.6,3.4,41.6,55.6,41.6,55.6h-30.6v0.1H210.5z M185,410.9c-6.8,0-13.2,0-13.2,0v40c0,0,9.1,0,15.3,0
            c8.4,0,19-5.7,19-20.8C206,415.8,196.1,410.9,185,410.9z"/>
        <path fill="#EB4934" d="M294.4,521.9V390.5h24.9v131.4H294.4L294.4,521.9z"/>
        <path fill="#EB4934" d="M427.4,413.4v108.4h-25.1V413.4h-27v-22.9H454v22.9H427.4z"/>
        <path fill="#FAD813" d="M714.5,523.2c-35.8,0-63.4-30.4-63.4-66.3c0-36.6,28.3-68.3,65-68.3c15.9,0,32.2,8.1,32.2,8.1v27.6
            c0,0-11.9-12.1-32.2-12.1c-22.7,0-39.8,20.2-39.8,42.7c0,23.4,16.3,44.7,39.8,44.7c13.8,0,23.6-5.5,33-12.1c0,0,0,18.4,0,26.5
            C745.3,516.9,731.3,523.2,714.5,523.2z"/>
        <path fill="#FAD813" d="M853.9,522.6c-36.9,0-66.2-30.2-66.2-67.1c0-38.2,29.7-67.1,66.5-67.1c37,0,67.1,29.9,67.1,67.1
            C921.4,492.6,890.8,522.6,853.9,522.6z M854.1,412.2c-24.4,0-41.9,18.4-41.9,43.6c0,24.5,17.9,43.2,41.9,43.2
            c24.4,0,42.6-19.5,42.6-43.2C896.5,430.2,878.3,412.2,854.1,412.2z"/>
        <polygon fill="#9FD5BA" points="600.8,469.4 600.8,444.5 562.4,444.5 562.4,406 537.4,406 537.4,444.5 499,444.5 499,469.4 
            537.4,469.4 537.4,507.9 562.4,507.9 562.4,469.4     "/>
    </g>
    </svg>
);

