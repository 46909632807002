
import React from 'react';


export default () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
             viewBox="0 0 134 12">
        <g>
            <path d="M5.4,2.93v8.32H2.63V2.93H0.36V0.57h7.31v2.35H5.4z"/>
            <path d="M18.94,9.46h-3.87l-0.72,1.79h-2.93l4.1-10.68h3.03l4.02,10.68H19.6L18.94,9.46z M18.2,7.34L17,3.89l-1.17,3.46H18.2z"/>
            <path d="M28.09,8.05c0.59,0.56,1.49,1.09,2.45,1.09c0.56,0,0.9-0.18,1.07-0.37c0.16-0.16,0.27-0.4,0.27-0.67
                    c0-0.21-0.08-0.45-0.3-0.64C31.31,7.22,30.89,7.1,30.2,6.9l-0.72-0.22c-0.45-0.14-1.1-0.38-1.54-0.86
                    c-0.54-0.59-0.61-1.34-0.61-1.89c0-1.31,0.51-2.11,0.98-2.58c0.54-0.54,1.44-1.01,2.74-1.01c1.06,0,2.29,0.34,3.3,0.86l-1.1,2.16
                    c-0.83-0.66-1.46-0.72-1.82-0.72c-0.27,0-0.64,0.06-0.91,0.32c-0.16,0.16-0.27,0.34-0.27,0.58c0,0.19,0.03,0.34,0.14,0.48
                    c0.08,0.1,0.27,0.29,0.93,0.48l0.85,0.26c0.67,0.21,1.38,0.45,1.94,1.06c0.54,0.58,0.69,1.17,0.69,1.9c0,0.98-0.24,1.92-1.01,2.69
                    c-0.91,0.91-2.02,1.09-3.15,1.09c-0.67,0-1.31-0.06-2.1-0.35c-0.18-0.06-0.85-0.32-1.62-0.85L28.09,8.05z"/>
            <path d="M44.56,2.93v8.32h-2.77V2.93h-2.27V0.57h7.32v2.35H44.56z"/>
            <path d="M58.12,2.93h-3.3V4.7h3.12v2.35h-3.12V8.9h3.3v2.35h-6.07V0.57h6.07V2.93z"/>
            <path d="M63.4,11.25l1.81-10.68h2.74l2.16,5.7l2.29-5.7h2.77l1.6,10.68H74L73.21,5.1l-2.58,6.15h-1.1L67.07,5.1l-0.91,6.15H63.4z"
                    />
            <path d="M89.04,9.46h-3.87l-0.72,1.79h-2.93l4.1-10.68h3.03l4.02,10.68h-2.96L89.04,9.46z M88.3,7.34l-1.2-3.46l-1.17,3.46H88.3z"
                    />
            <path d="M100.65,4.98l3.39-4.4h3.43l-4.24,5.06l4.63,5.62h-3.59l-3.62-4.63v4.63h-2.77V0.57h2.77V4.98z"/>
            <path d="M119.15,2.93h-3.3V4.7h3.12v2.35h-3.12V8.9h3.3v2.35h-6.07V0.57h6.07V2.93z"/>
            <path d="M129.23,0.57c0.8,0,1.82,0.13,2.61,0.94c0.61,0.62,0.91,1.55,0.91,2.4c0,0.91-0.32,1.71-0.85,2.23
                    c-0.42,0.4-0.98,0.64-1.47,0.74l3.35,4.37h-3.44l-2.66-4.1v4.1h-2.77V0.57H129.23z M127.67,5.36h0.53c0.42,0,1.01-0.11,1.34-0.43
                    c0.21-0.21,0.35-0.56,0.35-0.9c0-0.32-0.13-0.67-0.37-0.9c-0.26-0.26-0.72-0.42-1.33-0.42h-0.53V5.36z"/>
        </g>
    </svg>
);
